import {
  ArrowRightOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HistoryOutlined,
  InboxOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Input,
  List,
  MenuProps,
  Modal,
  Popover,
  Row,
  Select,
  Steps,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import _, { result } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Buttons from "../../components/Button/Button";
import Contrainer from "../../components/Contrainer/Contrainer";
import Text from "../../components/Text/Text";
import { categotyKciDatasource } from "../../datasource/categotyKciDatasource";
import { EmployeeApproveDatasource } from "../../datasource/EmploeeApprove";
import { kciDatasource } from "../../datasource/kciDatasource";
import {
  KCI_HISTORY_STATUS,
  KCI_STATUS_COLOR_MAPPING,
  KCI_STATUS_MAPPING,
} from "../../definitions/KciStatus";
import color from "../../resource/color";
import icon from "../../resource/icon";
import { useForm, useWatch } from "antd/lib/form/Form";
import ReactQuill from "react-quill";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { departmentDatasource } from "../../datasource/departmentDatasource";
import VirtualList from "rc-virtual-list";

const QuillContainer = styled.div`
  .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0 !important;
  }
  .ql-container.ql-snow {
    border-radius: 0 0 4px 4px !important;
  }
  .ql-editor {
    max-height: 100px;
    overflow: auto;
    background-color: #ffff;
  }
`;

const INITAIL_PAGE = 1;

const IndexKci: React.FC = () => {
  const [form] = useForm();
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem("rainbowProfile")!);
  const [loading, setLoading] = useState<boolean>(true);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const [selectPeriod, setSelectPeriod] = useState<string>("KCI");
  const yearList = [2024, 2025];
  const period = ["KCI ของฉัน", "Full Year", "Q1", "Q2", "Q3", "Q4"];
  const [rowIndex, setRowIndex] = useState<any>("");
  const [modalMove, setModalMove] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [modalCancelDelete, setModalCancelDelete] = useState<boolean>(false);
  const [modalCopy, setModalCopy] = useState<boolean>(false);
  const [modalShowDataDeleted, setModalShowDataDeleted] =
    useState<boolean>(false);

  const changeActual = useWatch("actual", form);
  const changeWeight = useWatch("weight", form);

  const targetList = [
    { label: "Full Year", color: "" },
    { label: "Q1", color: "" },
    { label: "Q2", color: "" },
    { label: "Q3", color: "" },
    { label: "Q4", color: "" },
  ];

  const status = [
    { labelTh: "ทั้งหมด", labelEn: "", color: "" },
    { labelTh: "อนุมัติ", labelEn: "APPROVE", color: "#26CC73" },
    { labelTh: "ไม่อนุมัติ", labelEn: "REJECT", color: "#EB5757" },
    { labelTh: "รออนุมัติ", labelEn: "WAIT_APPROVE", color: "#F7D564" },
    { labelTh: "แบบร่าง", labelEn: "DRAFT", color: "#B1B4B2" },
  ];

  const [searchEmp, setSearchEmp] = useState<any>(Number(profile.employeeId));
  const [selectYear, setSelectYear] = useState<number>(yearList[0]);
  const [category, setCategory] = useState<any>();
  const [editKci, setEditKci] = useState<any>();
  const [dataKci, setDataKci] = useState<any>();
  const [tab, setTab] = useState<any>();
  const [search, setSearch] = useState<string>();
  const [departmentList, setDepartmentList] = useState<any>();
  const [oneKci, setOneKci] = useState<any>();
  const [kciHistory, setKciHistory] = useState<any>();
  const [moveTarget, setMoveTarget] = useState<string>();
  const [page, setPage] = useState(INITAIL_PAGE);
  const [notiList, setNotiList] = useState<{
    count: number;
    data: any;
    summary?: any;
  }>({
    count: 0,
    data: [],
  });
  const [openNoti, setOpenNoti] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchTabNoti, setSearchTabNoti] = useState<any>(undefined);
  const [textEditor, setTextEditor] = useState<any>(undefined);
  const [commentList, setCommentList] = useState<any>();
  const [empList, setEmpList] = useState<any>();
  const [filterEmp, setFilterEmp] = useState<any>(undefined);
  const [selectedEmp, setSelectedEmp] = useState<any>([]);
  const [selectedCate, setSelectedCate] = useState<any>([]);
  const [selectDepart, setSelectDepart] = useState<any>();
  const [wordingButton, setWordingButton] = useState<boolean>(false);

  const getKciByEmpId = async () => {
    setLoading(true);
    setDataKci([]);
    let pushEmp: any[] = [];
    const findEmp = await EmployeeApproveDatasource.getEmployeeApprove(
      profile?.employeeId
    ).then((res) => {
      return res.responseData || [];
    });
    if (selectedEmp.length < 1) {
      pushEmp.push(profile?.employeeId);
      if (findEmp?.length > 0 && !selectPeriod?.includes("KCI")) {
        findEmp.forEach((x: any) => {
          pushEmp.push(Number(x.employeeId));
        });
      }
    } else {
      selectedEmp?.forEach((x: any) => {
        pushEmp.push(Number(x));
      });
    }
    let mapChecked: any = [];
    await kciDatasource
      .getKci({
        years: selectYear,
        employeeId: pushEmp,
        target: selectPeriod.includes("KCI") ? undefined : selectPeriod,
        kciStatus: tab ? tab : undefined,
        search,
        categoryId: selectedCate,
      })
      .then(async (res) => {
        mapChecked = [];
        if (selectedEmp.length < 1) {
          if (selectPeriod.includes("KCI")) {
            await period
              .filter((x: any) => !x.includes("KCI"))
              .forEach(async (y: any) => {
                let matchWithName = await res.data.filter((x: any) => {
                  return x.target === y;
                });
                const currentPeriod = [
                  {
                    key: "section",
                    name: y,
                  },
                  ...matchWithName,
                  {
                    key: "total",
                    weight: matchWithName
                      .map((x: any) => Number(x.weight))
                      .reduce((acc: any, curr: any) => {
                        return acc + curr;
                      }, 0),
                    actual: matchWithName
                      .map((x: any) => Number(x.actual))
                      .reduce((acc: any, curr: any) => {
                        return acc + curr;
                      }, 0),
                    success: matchWithName
                      .map((x: any) => Number(x.success))
                      .reduce((acc: any, curr: any) => {
                        return acc + curr;
                      }, 0),
                  },
                ];
                mapChecked.push(...currentPeriod);
              });
          } else {
            const empKci = await res?.data?.filter(
              (x: any) => `${x.employeeId}` === `${profile?.employeeId}`
            );
            const approveKci = await res?.data?.filter(
              (x: any) => `${x.employeeId}` !== `${profile?.employeeId}`
            );
            if (tab?.includes("WAIT_APPROVE")) {
              let currentPeriod = [];
              if (approveKci.length > 0) {
                currentPeriod.push(
                  {
                    key: "section",
                    name: "รอการอนุมัติ",
                  },
                  ...approveKci
                );
              }
              currentPeriod.push(
                {
                  key: "section",
                  name: "KCI ของฉัน",
                },
                ...empKci
              );
              mapChecked.push(...currentPeriod);
            } else if (tab?.includes("REJECT")) {
              let currentPeriod = [
                {
                  key: "section",
                  name: "KCI ของฉัน",
                },
                ...empKci,
              ];
              if (approveKci.length > 0) {
                currentPeriod.push(
                  {
                    key: "section",
                    name: "ประวัติการไม่อนุมัติ",
                  },
                  ...approveKci
                );
              }
              mapChecked.push(...currentPeriod);
            } else if (tab?.includes("APPROVE")) {
              const approve = approveKci.filter(
                (x: any) => x.kciStatus === "APPROVE"
              );

              const waitDelete = approveKci.filter(
                (x: any) => x.kciStatus === "WAIT_APPROVE_DELETE"
              );

              let currentPeriod = [
                {
                  key: "section",
                  name: "KCI ของฉัน",
                },
                ...empKci,
              ];
              if (approveKci.length > 0) {
                if (waitDelete?.length > 0) {
                  currentPeriod.push(
                    {
                      key: "section",
                      name: "รออนุมัติการลบ",
                    },
                    ...waitDelete
                  );
                }
                if (approve?.length > 0) {
                  currentPeriod.push(
                    {
                      key: "section",
                      name: "ประวัติการอนุมัติ",
                    },
                    ...approve
                  );
                }
              }
              mapChecked.push(...currentPeriod);
            } else if (tab?.includes("DRAFT")) {
              const myKci = [
                {
                  key: "section",
                  name: "KCI ของฉัน",
                },
                ...empKci,
              ];
              mapChecked.push(...myKci);
            } else {
              if (empKci?.length > 0) {
                const myKci = [
                  {
                    key: "section",
                    name: `${empKci[0]?.employee?.firstname} ${empKci[0]?.employee?.lastname}`,
                  },
                  ...empKci,
                ];
                mapChecked.push(...myKci);
              }
              const groupEmp = _.groupBy(approveKci, ({ employeeId }: any) => {
                return employeeId;
              });
              const dataEmp = Object.keys(groupEmp).map((key) => {
                return groupEmp[key];
              });
              dataEmp?.forEach((x) => {
                const find = res.data.filter(
                  (y: any) => `${y.employeeId}` === `${x[0]?.employeeId}`
                );
                const currentPeriod = [
                  {
                    key: "section",
                    name: `${x[0]?.employee?.firstname} ${x[0]?.employee?.lastname}`,
                  },
                  ...find,
                ];
                mapChecked.push(...currentPeriod);
              });
            }
          }
        } else {
          const groupEmp = _.groupBy(res?.data, ({ employeeId }: any) => {
            return employeeId;
          });
          const dataEmp = Object.keys(groupEmp).map((key) => {
            return groupEmp[key];
          });
          dataEmp?.forEach((x) => {
            const find = res.data.filter(
              (y: any) => `${y.employeeId}` === `${x[0]?.employeeId}`
            );
            const currentPeriod = [
              {
                key: "section",
                name: `${x[0]?.employee?.firstname} ${x[0]?.employee?.lastname}`,
              },
              ...find,
            ];
            mapChecked.push(...currentPeriod);
          });
        }
        mapChecked = await mapChecked.map((x: any) => {
          return { ...x, isChecked: false };
        });
        await setDataKci(mapChecked);
        setLoading(false);
      });

    await kciDatasource
      .getKci({
        years: selectYear,
        employeeId: pushEmp,
      })
      .then((res) => {
        setEditKci(res.data);
      });
  };

  const getOneKci = async (kciId: string) => {
    await kciDatasource.getKciById(kciId).then((res) => {
      setOneKci(res.responseData);
      form.setFieldsValue({
        kciid: res.responseData.kciId,
        kciDescription: res.responseData.kciDescription,
        target: res.responseData.target,
        category: res.responseData.categoryId,
        weight: Number(res.responseData.weight) || 0,
        actual: Number(res.responseData.actual) || 0,
        success: Number(res.responseData.success) || 0,
      });
    });
    await kciDatasource.getCommentKciById(kciId).then((res) => {
      setCommentList(res);
    });
  };

  const getCategyKci = async () => {
    await categotyKciDatasource.getCategory().then((res) => {
      setCategory(res.responseData);
    });
  };

  const getNoti = async () => {
    await kciDatasource
      .getNotiList({
        employeeId: profile.employeeId,
        page: 1,
        take: 10,
        isRead: searchTabNoti,
      })
      .then((res) => {
        setNotiList(res);
        setPage(INITAIL_PAGE);
      });
  };

  const onLoadMore = async (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isEndReach = scrollTop + clientHeight >= scrollHeight;
    try {
      if (!isEndReach) {
        return;
      }
      if (notiList?.count <= notiList?.data?.length) {
        return;
      }
      await kciDatasource
        .getNotiList({
          employeeId: profile.employeeId,
          page: page + 1,
          take: 10,
          isRead: searchTabNoti,
        })
        .then((res) => {
          if (res) {
            setPage((prev) => prev + 1);

            setNotiList((prev) => {
              return {
                ...prev,
                count: res.count,
                data: [...prev.data, ...res.data],
              };
            });
          }
        });
    } catch (error) {
      throw error;
    }
  };

  const getAllApprove = async () => {
    await EmployeeApproveDatasource.getAllEmployee({
      page: 1,
      take: 10,
      search: filterEmp,
      companyId: profile.companyId,
      isActive: true,
    }).then((res) => {
      setEmpList(res.responseData);
    });
  };

  const getDepartmentByComId = async () => {
    await departmentDatasource
      .getDepartmentByCompId(profile.companyId)
      .then((res) => {
        setDepartmentList(res.responseData);
      });
  };

  useEffect(() => {
    getKciByEmpId();
    getCategyKci();
    getNoti();
    getDepartmentByComId();
    setWordingButton(false);
  }, [
    selectPeriod,
    tab,
    search,
    selectYear,
    searchEmp,
    selectedCate,
    selectDepart,
  ]);

  useEffect(() => {
    getNoti();
  }, [searchTabNoti]);

  useEffect(() => {
    getAllApprove();
  }, [filterEmp]);

  useEffect(() => {
    let payload = form.getFieldsValue(true);
    payload.actual = changeActual;
    payload.weight = changeWeight;
    form.setFieldsValue(payload);
    form.setFieldValue(
      "success",
      (Number(changeWeight) * Number(changeActual)) / 100
    );
  }, [changeActual, changeWeight]);

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  };

  const handleCheckBox = (e: any, kciId: string) => {
    const checked = e.target.checked;
    let mapData = dataKci;
    mapData = mapData.map((x: any) => {
      const find = x.kciId === kciId;
      if (find) {
        return { ...x, isChecked: checked };
      } else {
        return { ...x };
      }
    });
    setDataKci(mapData);
  };

  const handleAllCheckBox = (e: any) => {
    const checked = e.target.checked || false;
    let mapData = dataKci;
    mapData = mapData.map((x: any) => {
      return { ...x, isChecked: checked };
    });
    setDataKci(mapData);
  };

  const selectOption = () => {
    let items: MenuProps["items"] = [
      {
        label: (
          <>
            <img src={icon.kciApprove} style={{ fontSize: "20px" }} />{" "}
            <Text fontWeight={700} fontSize={16}>
              การอนุมัติคำขอลบ
            </Text>
          </>
        ),
        key: "approveDelete", //เปลี่ยน function
        children: [
          {
            key: "approve",
            label: (
              <div onClick={() => approveDelete(true)}>
                <CheckOutlined
                  style={{ color: color.success, fontSize: "20px" }}
                />{" "}
                <Text color="success" fontWeight={700} fontSize={16}>
                  อนุมัติ
                </Text>
              </div>
            ),
          },
          {
            key: "reject",
            label: (
              <div onClick={() => approveDelete(false)}>
                <CloseOutlined
                  style={{ color: color.error, fontSize: "18px" }}
                />{" "}
                <Text color="error" fontWeight={700}>
                  ไม่อนุมัติ
                </Text>
              </div>
            ),
          },
        ],
      },
      {
        label: (
          <>
            <img src={icon.kciApprove} style={{ fontSize: "20px" }} />{" "}
            <Text fontWeight={700} fontSize={16}>
              การอนุมัติ
            </Text>
          </>
        ),
        key: "manager",
        children: [
          {
            key: "approve",
            label: (
              <div onClick={() => kciApprove("APPROVE")}>
                <CheckOutlined
                  style={{ color: color.success, fontSize: "20px" }}
                />{" "}
                <Text color="success" fontWeight={700} fontSize={16}>
                  อนุมัติ
                </Text>
              </div>
            ),
          },
          {
            key: "reject",
            label: (
              <div onClick={() => kciApprove("REJECT")}>
                <CloseOutlined
                  style={{ color: color.error, fontSize: "18px" }}
                />{" "}
                <Text color="error" fontWeight={700}>
                  ไม่อนุมัติ
                </Text>
              </div>
            ),
          },
        ],
      },
      {
        label: (
          <div
            onClick={() => {
              setOpenHistory(true);
              getKciHistory(dataKci?.filter((x: any) => x.isChecked)[0].kciId);
            }}
          >
            <img src={icon.kcihistory} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              ประวัติการทำรายการ
            </Text>
          </div>
        ),
        key: "history",
      },
      {
        label: (
          <div
            onClick={() => {
              setModalMove(true);
              getOneKci(dataKci?.filter((x: any) => x.isChecked)[0].kciId);
            }}
          >
            <img src={icon.kcimove} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              ย้ายกลุ่ม
            </Text>
          </div>
        ),
        key: "move",
      },
      {
        label: (
          <div onClick={() => setModalCopy(true)}>
            <img src={icon.kcicopy} style={{ fontSize: "20px" }} />{" "}
            <Text color="black" fontWeight={700} fontSize={16}>
              คัดลอก
            </Text>
          </div>
        ),
        key: "copy",
      },
      {
        label: (
          <div onClick={() => setModalDelete(true)}>
            <DeleteOutlined style={{ fontSize: "20px", color: color.error }} />{" "}
            <Text color="error" fontWeight={700} fontSize={16}>
              ลบ
            </Text>
          </div>
        ),
        key: "delete",
      },
      {
        label: (
          <div onClick={() => setModalCancelDelete(true)}>
            <CloseOutlined style={{ fontSize: "20px", color: color.error }} />{" "}
            <Text color="error" fontWeight={700} fontSize={16}>
              ยกเลิกขออนุมัติการลบ
            </Text>
          </div>
        ),
        key: "cancelDelete",
      },
    ];

    const optionMap = dataKci.filter((x: any) => x.isChecked);
    if (optionMap.length > 1) {
      let groupEmp: any = _.groupBy(
        optionMap,
        ({ employeeId }: any) => employeeId
      );
      const groupStatus: any = _.groupBy(
        optionMap,
        ({ kciStatus }: any) => kciStatus
      );
      const dataEmp = Object.keys(groupEmp).map((key) => {
        return groupEmp[key];
      });
      const dataStatus = Object.keys(groupStatus).map((key) => {
        return groupStatus[key];
      });

      if (dataEmp.length > 1 || dataStatus.length > 1) {
        items = items.filter((x) => x?.key === "copy");
      } else {
        const checkMyApprove = dataStatus[0][0].isMyApprove;
        const checkStatus = dataStatus[0][0].kciStatus;
        if (checkMyApprove) {
          if (checkStatus.includes("DELETE")) {
            items = items.filter(
              (x) => x?.key === "approveDelete" || x?.key === "copy"
            );
          } else if (checkStatus.includes("APPROVE")) {
            items = items.filter(
              (x) =>
                x?.key === "manager" || x?.key === "copy" || x?.key === "delete"
            );
          } else {
            items = items.filter(
              (x) => x?.key === "copy" || x?.key === "delete"
            );
          }
        } else {
          if (checkStatus.includes("WAIT")) {
            items = items.filter((x) => x?.key === "copy");
          } else {
            items = items.filter(
              (x) => x?.key === "copy" || x?.key === "delete"
            );
          }
        }
      }
    } else {
      const checkMyApprove = optionMap[0]?.isMyApprove;
      const checkSelfKci =
        `${optionMap[0].employeeId}` === `${profile.employeeId}`;
      const checkStatus = optionMap[0].kciStatus;
      if (checkMyApprove) {
        if (checkStatus?.includes("DELETE")) {
          items = items.filter(
            (x) => x?.key === "copy" || x?.key === "approveDelete"
          );
        } else if (checkStatus?.includes("WAIT_APPROVE")) {
          items = items.filter(
            (x) =>
              x?.key === "manager" || x?.key === "history" || x?.key === "copy"
          );
        } else {
          items = items.filter(
            (x) => x?.key === "history" || x?.key === "copy"
          );
        }
      } else {
        if (checkSelfKci) {
          if (checkStatus?.includes("DELETE")) {
            items = items.filter(
              (x) =>
                x?.key !== "manager" &&
                x?.key !== "delete" &&
                x?.key !== "approveDelete"
            );
          } else if (checkStatus?.includes("WAIT_APPROVE")) {
            items = items.filter(
              (x) =>
                x?.key === "history" || x?.key === "move" || x?.key === "copy"
            );
          } else {
            items = items.filter(
              (x) =>
                x?.key === "history" ||
                x?.key === "move" ||
                x?.key === "copy" ||
                x?.key === "delete"
            );
          }
        } else {
          items = items.filter(
            (x) => x?.key === "history" || x?.key === "copy"
          );
        }
      }
    }

    return (
      <>
        <Row justify={"start"} gutter={8}>
          <Col span={7}>
            <Dropdown menu={{ items }}>
              <Button
                style={{
                  border: "none",
                  backgroundColor: "#F3F4FB",
                  width: "100%",
                }}
              >
                <Row justify={"space-between"}>
                  <Col>
                    <Text color="navy" level={6} fontWeight={700}>
                      เลือก {optionMap?.length} รายการ
                    </Text>
                  </Col>
                  <Col>
                    <DownOutlined color="navy" />
                  </Col>
                </Row>
              </Button>
            </Dropdown>
          </Col>
          <Col style={{ paddingTop: "8px" }}>
            <Text
              level={6}
              color="navy"
              style={{ cursor: "pointer" }}
              onClick={(e: any) => handleAllCheckBox(e)}
            >
              <u>ล้างการเลือก</u>
            </Text>
          </Col>
        </Row>
        <br />
      </>
    );
  };

  const getKciHistory = async (kciId: string) => {
    await kciDatasource.getKciLog(kciId).then((res) => {
      let groupTime: any = _.groupBy(res.responseData, ({ createdAt }: any) =>
        moment(createdAt).format("DD/MM/YYYY")
      );
      const dataHis = Object.keys(groupTime).map((key) => {
        return groupTime[key];
      });
      setKciHistory(dataHis);
    });
  };

  const copyKci = async (kciId?: string) => {
    const kciIds: any = [];
    if (kciId) {
      kciIds.push(kciId);
    } else {
      const dataCopy = dataKci.filter((x: any) => x.isChecked);
      dataCopy.forEach((e: any) => {
        kciIds.push(e.kciId);
      });
    }

    await kciDatasource
      .copyKci({
        kciId: kciIds,
        employeeId: Number(profile?.employeeId),
        createBy: `${profile.firstname} ${profile.lastname}`,
      })
      .then((res) => {
        setModalCopy(false);
        if (res.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "คัดลอกสำเร็จ",
            //showConfirmButton: true,
            width: 360,
          });
          getKciByEmpId();
        }
      });
  };

  const moveKci = async () => {
    const dataMove = dataKci.find((x: any) => x.isChecked);
    const mapStatus = moveTarget?.includes("Year") ? "WAIT_APPROVE" : "APPROVE";
    await kciDatasource
      .moveKci({
        kciId: [dataMove.kciId],
        target: moveTarget,
        updateBy: `${profile.firstname} ${profile.lastname}`,
        kciStatus: mapStatus,
      })
      .then((res) => {
        if (res.success) {
          setModalMove(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "ย้ายกลุ่มสำเร็จ",
            //showConfirmButton: true,
            width: 360,
          });
          getKciByEmpId();
        }
      });
  };

  const deleteKci = async (kciId?: string) => {
    let payloadYear: any = [];
    let payloadQ: any = [];
    let mapDelete: any = [];

    if (kciId) {
      mapDelete = dataKci.filter((x: any) => x.kciId === kciId);
    } else {
      mapDelete = dataKci.filter((x: any) => x.isChecked);
    }

    const findFullYear = mapDelete.filter((a: any) =>
      a.target.includes("Full")
    );
    const findQ = mapDelete.filter((b: any) => b.target.includes("Q"));

    if (findFullYear.length > 0) {
      findFullYear.forEach((e: any) => {
        if (e.kciStatus.includes("APPROVE")) {
          payloadYear.push({
            kciId: e.kciId,
            kciDescription: e.kciDescription,
            categoryId: e.categoryId,
            target: e.target,
            years: e.years,
            actual: e.actual,
            weight: e.weight,
            success: e.success,
            employeeId: e.employeeId,
            updateBy: `${profile.firstname} ${profile.lastname}`,
            //isCancelDelete: false,
            kciStatus: "WAIT_APPROVE_DELETE",
          });
        } else {
          payloadQ.push(e.kciId);
        }
      });
    }

    if (findQ.length > 0) {
      findQ.forEach((e: any) => {
        payloadQ.push(e.kciId);
      });
    }

    if (payloadYear.length > 0) {
      await kciDatasource.updateKci(payloadYear).then((res) => {
        if (res.success) {
          setOpenEdit(false);
          setModalDelete(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "ส่งคำขอลบ KCI สำเร็จ",
            width: 360,
          });
          getKciByEmpId();
        }
      });
    }

    if (payloadQ.length > 0) {
      await kciDatasource
        .deleteKci({
          kciId: payloadQ,
          isApproveDelete: false,
          isCancelApproveDelete: false,
        })
        .then((res) => {
          if (res.success) {
            setModalDelete(false);
            setOpenEdit(false);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "ลบสำเร็จ",
              width: 360,
            });
            getKciByEmpId();
          }
        });
    }
  };

  const approveDelete = async (isApprove: boolean) => {
    let mapDelete: any = [];
    if (dataKci.filter((x: any) => x.isChecked).length > 0) {
      const data = dataKci.filter((x: any) => x.isChecked);
      data.forEach((e: any) => {
        mapDelete.push(e.kciId);
      });
    } else {
      mapDelete = [oneKci.kciId];
    }
    await kciDatasource
      .deleteKci({
        kciId: mapDelete,
        isApproveDelete: isApprove,
        isCancelApproveDelete: !isApprove,
      })
      .then((res) => {
        if (res.success) {
          setModalDelete(false);
          setOpenEdit(false);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "อนุมัติการลบสำเร็จ",
            width: 360,
          });
          getKciByEmpId();
        }
      });
  };

  const kciApprove = async (action: string) => {
    let payload: any = [];
    const mapDelete = dataKci.filter((x: any) => x.isChecked);
    if (mapDelete.length > 0) {
      mapDelete.forEach((e: any) => {
        payload.push(e.kciId);
      });
    } else {
      payload.push(oneKci.kciId);
    }
    await kciDatasource
      .approveKci({
        kciId: payload,
        kciStatus: action,
        updateBy: `${profile.firstname} ${profile.lastname}`,
      })
      .then((res) => {
        if (res.success) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `ยืนยันการ${
              action === "APPROVE" ? `อนุมัติ` : `ไม่อนุมัติ`
            } ${payload.length > 0 ? payload.length : ""} รายการสำเร็จ`,
            showConfirmButton: true,
            width: 360,
          }).then((value) => {
            if (value.isConfirmed) {
              setOpenEdit(false);
              getKciByEmpId();
            }
          });
        }
      });
  };

  const editKciByManager = async (status?: string) => {
    let payload = form.getFieldsValue(true);
    let checkActual = `${payload.actual}` === `${oneKci.actual}`;
    const checkValidate = () => {
      let list = [];
      let description = payload.kciDescription === oneKci.kciDescription;
      let target = payload.target === oneKci.target;
      let weight = `${payload.weight}` === `${oneKci.weight}`;

      list.push(description, target, weight);
      let final = list.filter((x) => !x);
      return final.length > 0 ? true : false;
    };

    let mapData = { ...oneKci };
    mapData.kciDescription = payload.kciDescription;
    mapData.kciStatus = status
      ? status
      : !checkActual && !checkValidate()
      ? oneKci.kciStatus
      : payload.target.includes("Year")
      ? "WAIT_APPROVE"
      : "APPROVE";
    mapData.categoryId = payload.category;
    mapData.target = payload.target;
    mapData.actual = payload.actual;
    mapData.weight = payload.weight;
    mapData.success = payload.success;

    await kciDatasource.updateKci([mapData]).then((res) => {
      if (res.success) {
        setOpenEdit(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "แก้ไข KCI สำเร็จ",
          width: 360,
        });
        getKciByEmpId();
      }
    });
  };

  const cancelDeleteKci = async () => {
    let mapData: any = {};
    mapData.kciId =
      oneKci?.kciId || dataKci.find((x: any) => x.isChecked).kciId;
    mapData.isCancelDelete = true;
    mapData.kciStatus = "APPROVE";
    mapData.categoryId =
      oneKci?.categoryId || dataKci.find((x: any) => x.isChecked).categoryId;
    await kciDatasource.updateKci([mapData]).then((res) => {
      if (res.success) {
        setOpenEdit(false);
        setModalCancelDelete(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "ยกเลิกการขออนุมัติลบสำเร็จ",
          width: 360,
        });
        getKciByEmpId();
      }
    });
  };

  const validateAtLeastOne = (_: unknown, value: any) => {
    const allValues = form.getFieldsValue();
    if (oneKci?.target.includes("Year")) {
      if (
        `${oneKci?.kciDescription}` === `${allValues.kciDescription}` &&
        `${oneKci?.categoryId}` === `${allValues.category}` &&
        `${oneKci?.weight}` === `${allValues.weight}`
      ) {
        setWordingButton(false);
      } else {
        setWordingButton(true);
      }
    }
  };

  const tabChildren = () => {
    return (
      <List>
        {notiList?.data.length > 0 ? (
          <VirtualList
            data={notiList?.data}
            itemKey="notificationId"
            itemHeight={50}
            height={400}
            onScroll={onLoadMore}
          >
            {(item: any) => {
              return (
                <List.Item key={item?.notificationId}>
                  <List.Item.Meta
                    avatar={
                      <>
                        {!item.isRead && (
                          <ConfigProvider
                            theme={{
                              token: {
                                fontSize: 25,
                              },
                            }}
                          >
                            <Badge color={color.navy} />
                          </ConfigProvider>
                        )}{" "}
                        {item?.employee?.employeeImage ? (
                          <Avatar src={item?.employee?.employeeImage} />
                        ) : (
                          <Avatar>
                            {item?.employee?.firstname.substring(0, 1) || ""}
                            {item?.employee?.lastname.substring(0, 1) || ""}
                          </Avatar>
                        )}
                      </>
                    }
                    title={
                      <>
                        <Text fontWeight={700} level={6}>
                          {item?.employee?.firstname} {item?.employee?.lastname}
                        </Text>{" "}
                        <Text level={6} fontWeight={400}>
                          {item?.action} หัวข้อ:{item?.kci?.kciDescription}
                        </Text>
                      </>
                    }
                    description={
                      <>
                        <Text level={6} color="gray">
                          {moment(item?.createDate).format("DD/MM/YYYY")} |{" "}
                          {moment(item?.createDate).format("HH:mm")} •{" "}
                          {item?.action.includes("KCI")
                            ? "รายงาน"
                            : item?.action.includes("commented")
                            ? "Comment"
                            : "แก้ไข"}
                        </Text>
                        <br />
                        <Button
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#F4F4F6",
                          }}
                          size="small"
                          onClick={() => {
                            readNoti(item.notificationId);
                            setOpenNoti(false);
                            getOneKci(item.kciId);
                            if (!item.kci.isDelete) {
                              setOpenEdit(true);
                            } else {
                              setModalShowDataDeleted(true);
                            }
                          }}
                        >
                          <Text level={6}>
                            {!item.kci.isDelete ? "เปิดรายการ" : "ดูรายละเอียด"}
                          </Text>
                        </Button>
                      </>
                    }
                  />
                </List.Item>
              );
            }}
          </VirtualList>
        ) : (
          <Empty description={<Text level={6}>ไม่มีการแจ้งเตือน</Text>} />
        )}
      </List>
    );
  };

  const tabNoti: any = [
    {
      key: undefined,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          ทั้งหมด (
          {Number(notiList?.summary?.isRead) +
            Number(notiList?.summary?.unread) || 0}
          )
        </Text>
      ),
      children: tabChildren(),
    },
    {
      key: true,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          อ่านแล้ว ({Number(notiList?.summary?.isRead) || 0})
        </Text>
      ),
      children: tabChildren(),
    },
    {
      key: false,
      label: (
        <Text level={6} fontWeight={700} color="navy">
          ยังไม่ได้อ่าน ({Number(notiList?.summary?.unread) || 0})
        </Text>
      ),
      children: tabChildren(),
    },
  ];

  const notiContent = () => {
    return (
      <div style={{ width: "550px" }}>
        <Row justify={"space-between"} gutter={8}>
          <Col>
            <Text level={3} fontWeight={700}>
              แจ้งเตือน
            </Text>
          </Col>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearchTabNoti(undefined);
              setOpenNoti(false);
            }}
          >
            <CloseOutlined />
          </Col>
        </Row>
        <Tabs
          defaultActiveKey={searchTabNoti}
          items={tabNoti}
          onChange={(key) => setSearchTabNoti(key)}
        />
      </div>
    );
  };

  const readNoti = async (notiId: string) => {
    await kciDatasource.readKciNoti(notiId).then((res) => {
      if (res.success) {
        getNoti();
      }
    });
  };

  const saveComment = async (kciId: string) => {
    await kciDatasource
      .commentKci({
        kciId: kciId,
        employeeId: profile.employeeId,
        comment: textEditor,
      })
      .then((res) => {
        if (res.success) {
          setTextEditor(undefined);
          getOneKci(kciId);
        }
      });
  };

  const columns: any = [
    {
      title: selectPeriod?.includes("KCI")
        ? dataKci?.length > 10
        : dataKci?.length > 1 && (
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#34B16E",
                },
              }}
            >
              <Checkbox
                onClick={(e) => handleAllCheckBox(e)}
                checked={dataKci.every((x: any) => x.isChecked)}
              />
            </ConfigProvider>
          ),
      align: "left",
      key: "section",
      width: "3%",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            colSpan: 2,
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "section" && (
                    <Text level={6} fontWeight={700} color="navy">
                      {row.name === "Full Year" ? `${row.name} 2024` : row.name}
                    </Text>
                  )}
                </>
              ) : (
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#34B16E",
                    },
                  }}
                >
                  <Checkbox
                    onClick={(e) => handleCheckBox(e, value.kciId)}
                    checked={value.isChecked}
                  />
                </ConfigProvider>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <center>
          <Text level={6} fontWeight={700} color="navy">
            คำอธิบาย
          </Text>
        </center>
      ),
      dataIndex: "kciDescription",
      key: "kciDescription",
      align: "left",
      width: "50%",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <Row gutter={16}>
                  <Col>
                    <ConfigProvider
                      theme={{
                        token: {
                          fontSize: 25,
                        },
                      }}
                    >
                      <Badge color={KCI_STATUS_COLOR_MAPPING[row.kciStatus]} />
                    </ConfigProvider>
                  </Col>
                  <Col style={{ paddingTop: "10px" }}>
                    <Row gutter={8}>
                      <Col>
                        <Text fontWeight={500} level={6}>
                          {value}
                        </Text>{" "}
                      </Col>
                      {row.kciStatus.includes("DELETE") && (
                        <Col>
                          <div
                            style={{
                              paddingLeft: "4px",
                              backgroundColor: "#F79626",
                              width: "100px",
                              borderRadius: "5px",
                            }}
                          >
                            <Text fontSize={12} fontWeight={500} color="white">
                              <HistoryOutlined /> รออนุมัติการลบ
                            </Text>
                          </div>
                        </Col>
                      )}
                      {rowIndex === index && (
                        <Col>
                          <EditOutlined
                            style={{ color: color.gray, fontSize: "16px" }}
                            onClick={() => {
                              setOpenEdit(true);
                              getOneKci(row?.kciId);
                            }}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Owner
        </Text>
      ),
      key: "owner",
      dataIndex: "owner",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={5} fontWeight={500}>
                      รวม
                    </Text>
                  )}
                </>
              ) : (
                <div style={{ cursor: "default" }}>
                  {row?.employee?.employeeImage ? (
                    <Tooltip
                      title={`${row?.employee?.firstname} ${row?.employee?.lastname}`}
                    >
                      <Avatar src={row?.employee?.employeeImage || ""} />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={`${row?.employee?.firstname} ${row?.employee?.lastname}`}
                    >
                      <Avatar>
                        {row?.employee?.firstname.substring(0, 1) || ""}
                        {row?.employee?.lastname.substring(0, 1) || ""}
                      </Avatar>
                    </Tooltip>
                  )}
                </div>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Actual (%){" "}
          <Tooltip title="ระบุเปอร์เซ็นต์ที่ผลลัพธ์เกิดขึ้นจริง">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "actual",
      dataIndex: "actual",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={6} fontWeight={500}>
                      {row.actual} %
                    </Text>
                  )}
                </>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {value} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Weighted Avg{" "}
          <Tooltip title="ค่าเฉลี่ยถ่วงน้ำหนัก = Importance x Actual (%)">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "success",
      dataIndex: "success",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={6} fontWeight={500}>
                      {Number(row.success).toFixed(1)} %
                    </Text>
                  )}
                </>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {Number(value).toFixed(1)} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          หมวดหมู่{" "}
          <Tooltip title="เลือกมิติในการวัดผลการดำเนินงานขององค์กร แบ่งออกเป็น 4 มิติ">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "category",
      dataIndex: "category",
      align: "center",
      onCell: (value: any, row: any, index: number) => {
        return {
          style: {
            backgroundColor:
              value.key === "section" ? "#E8E9F8" : value?.category?.colorCode,
          },
        };
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <></>
              ) : (
                <Text level={6} fontWeight={500} color="white" key={index}>
                  {row?.category?.categoryName}
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: (
        <Text level={6} fontWeight={700} color="navy">
          Importance{" "}
          <Tooltip title="ระบุเปอร์เซ็นต์น้ำหนักความสำคัญของ KCI นั้นๆ">
            <ExclamationCircleFilled />
          </Tooltip>
        </Text>
      ),
      key: "weight",
      dataIndex: "weight",
      align: "center",
      onCell: (value: any, index: any) => {
        if (value.key === "section") {
          return {
            style: {
              backgroundColor: "#E8E9F8",
            },
          };
        }
      },
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <>
              {row.key === "section" || row.key === "total" ? (
                <>
                  {row.key === "total" && (
                    <Text level={6} fontWeight={500}>
                      {row.weight} %
                    </Text>
                  )}
                </>
              ) : (
                <Text level={6} fontWeight={500} key={index}>
                  {value} %
                </Text>
              )}
            </>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Contrainer>
        <Row justify="space-between" gutter={8}>
          <Col span={5}>
            <Select
              showSearch
              bordered={true}
              value={selectYear}
              options={yearList.map((x) => {
                return {
                  label: (
                    <Text color="black" fontWeight={700} fontSize={20}>
                      KCI {x}
                    </Text>
                  ),
                  value: x,
                  key: x,
                };
              })}
              onChange={(key) => setSelectYear(key)}
              virtual={false}
              style={{
                width: "95%",
                height: "120%",
                overflow: "scroll",
              }}
            />
          </Col>
          <Col style={{ paddingTop: "10px" }}>
            <Popover
              placement="leftTop"
              arrow={false}
              content={notiContent}
              trigger="click"
              open={openNoti}
            >
              <Badge
                size="small"
                count={Number(notiList?.summary?.unread) || 0}
              >
                <Button
                  style={{ backgroundColor: "#E9ECEF" }}
                  icon={<InboxOutlined style={{ fontSize: "20px" }} />}
                  onClick={() => {
                    setOpenNoti(true);
                    setSearchTabNoti(undefined);
                  }}
                />
              </Badge>
            </Popover>
          </Col>
        </Row>
        <Row justify={"end"} style={{ paddingTop: "4px" }}>
          <Text level={6}>
            อนุมัติโดย :{" "}
            {profile?.employeeApprove?.employeeApproveDetail?.firstname || ""}{" "}
            {profile?.employeeApprove?.employeeApproveDetail?.lastname || ""}
          </Text>
        </Row>
        <Divider />
        <Row gutter={8}>
          <Col>
            <Buttons
              onClick={() =>
                navigate(
                  `/createKci/${selectYear}/${
                    editKci?.length > 0 ? `${profile?.employeeId}` : 0
                  }`
                )
              }
              text={
                editKci?.length > 0
                  ? "แก้ไขรายการของฉัน"
                  : "สร้างรายการใหม่ของฉัน"
              }
              icon={
                editKci?.length > 0 ? (
                  <EditOutlined style={{ color: "white", fontSize: "20px" }} />
                ) : (
                  <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
                )
              }
            />
          </Col>
          <Col span={6}>
            <Input
              style={{ fontFamily: "Sarabun", width: "100%" }}
              suffix={<SearchOutlined />}
              placeholder="ค้นหาคำอธิบาย"
              onPressEnter={(e: any) => {
                if (e) {
                  setSearch(e.target.value);
                } else {
                  setSearch(undefined);
                }
              }}
            />
          </Col>
          {!selectPeriod.includes("KCI") && (
            <>
              <Col>
                <Popover
                  open={openFilter}
                  placement="bottomLeft"
                  content={
                    <div style={{ width: "500px" }}>
                      <Row justify={"space-between"}>
                        <Col>
                          <Text fontWeight={700}>บุคคล</Text>
                        </Col>
                        <Col>
                          <CloseOutlined
                            style={{ cursor: "pointer" }}
                            onClick={() => setOpenFilter(false)}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Text fontSize={14} color="navy">
                        โปรดเลือกบุคคลที่ต้องการทราบข้อมูล
                      </Text>
                      <br />
                      <Select
                        mode="multiple"
                        maxTagCount={1}
                        style={{ width: "100%" }}
                        allowClear
                        onSearch={(e) => {
                          if (e) {
                            setFilterEmp(e);
                          } else {
                            setFilterEmp(undefined);
                          }
                        }}
                        onSelect={(e) => {
                          setFilterEmp(undefined);
                          setSelectedEmp([...selectedEmp, e]);
                        }}
                        onChange={(e) => {
                          if (e.length > 0) {
                            setSelectedEmp([e]);
                          } else {
                            setSelectedEmp([]);
                          }
                        }}
                        onClear={() => {
                          setFilterEmp(undefined);
                          setSelectedEmp([]);
                        }}
                        options={empList?.map((y: any) => {
                          return {
                            label: (
                              <Row gutter={8} justify="start">
                                <Col>
                                  {y?.imagePath ? (
                                    <Avatar src={y?.imagePath} />
                                  ) : (
                                    <Avatar>
                                      {y?.firstname.substring(0, 1) || ""}
                                      {y?.lastname.substring(0, 1) || ""}
                                    </Avatar>
                                  )}
                                </Col>
                                <Col>
                                  <Text fontWeight={700}>
                                    {y?.firstname + " " + y?.lastname}/
                                    {y?.position.positionName}
                                  </Text>
                                </Col>
                              </Row>
                            ),
                            value: y?.employeeId,
                            key: y?.firstname + " " + y?.lastname,
                          };
                        })}
                        optionFilterProp="children"
                        filterOption={false}
                      />
                      <Row justify={"end"} style={{ paddingTop: "10px" }}>
                        <Button
                          size="small"
                          style={{
                            backgroundColor: color.navy,
                          }}
                          onClick={() => {
                            setSearchEmp(selectedEmp);
                            setOpenFilter(false);
                          }}
                        >
                          <Text level={6} color="white">
                            ค้นหา
                          </Text>
                        </Button>
                      </Row>
                    </div>
                  }
                  trigger="click"
                >
                  <Button
                    onClick={() => setOpenFilter(true)}
                    style={{ backgroundColor: "#E9ECEF" }}
                    icon={
                      <img src={icon.userIcon} style={{ fontSize: "25px" }} />
                    }
                  >
                    <Text level={5} fontWeight={700}>
                      บุคคล
                    </Text>
                    {selectedEmp?.length > 0 && (
                      <Badge
                        count={selectedEmp?.length}
                        color={color.navy}
                        size="small"
                      />
                    )}
                  </Button>
                </Popover>
              </Col>
              <Col span={3}>
                <Select
                  allowClear
                  style={{
                    backgroundColor: color.white,
                    height: "33px",
                    width: "100%",
                  }}
                  placeholder="เลือกแผนก"
                  bordered={true}
                  options={departmentList.map((d: any) => {
                    return {
                      label: d.departmentName,
                      value: d.departmentId,
                      key: d.departmentId,
                    };
                  })}
                  onChange={(e) => setSelectDepart(e)}
                />
              </Col>
              <Col span={5}>
                <Select
                  allowClear
                  style={{
                    backgroundColor: color.white,
                    height: "33px",
                    width: "100%",
                  }}
                  placeholder="ค้นหาหมวดหมู่"
                  bordered={true}
                  options={category?.map((c: any) => {
                    return {
                      label: (
                        <div
                          style={{
                            backgroundColor: c.colorCode,
                            borderRadius: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          <Text level={6} color="white" fontWeight={500}>
                            {c.categoryName}
                          </Text>
                        </div>
                      ),
                      value: c.categoryId,
                      key: c.categoryId,
                    };
                  })}
                  onChange={(e: any) => {
                    if (e) {
                      setSelectedCate([e]);
                    } else {
                      setSelectedCate([]);
                    }
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        <br />
        {dataKci?.filter((x: any) => x.isChecked).length > 0 && (
          <>{selectOption()}</>
        )}
        <Row>
          {period.map((x) => (
            <Button
              type="text"
              style={{
                backgroundColor: x.includes(selectPeriod) ? "#F3F4FB" : "",
              }}
              onClick={() => {
                setOpenFilter(false);
                if (x.includes("KCI")) {
                  setSearchEmp([]);
                  setSelectedEmp([]);
                  setSelectedCate(undefined);
                  setSelectDepart(undefined);
                }
                setSelectPeriod(x);
              }}
            >
              <Text
                underline={x.includes(selectPeriod)}
                level={6}
                fontWeight={x.includes(selectPeriod) ? 700 : 400}
                color={x.includes(selectPeriod) ? "navy" : "black"}
              >
                {x}
              </Text>
            </Button>
          ))}
        </Row>
        <br />
        <Tabs
          size="middle"
          type="card"
          onChange={(key) =>
            setTab(
              key === "APPROVE"
                ? ["APPROVE", "WAIT_APPROVE_DELETE"]
                : key
                ? [key]
                : undefined
            )
          }
          items={status.map((s: any, i: any) => {
            return {
              label: (
                <>
                  <Badge color={s.color} size="default" />{" "}
                  <Text level={6} color="navy" fontWeight={700}>
                    {s.labelTh}
                  </Text>
                </>
              ),
              key: s.labelEn,
              children: (
                <Table
                  loading={loading}
                  size={"small"}
                  columns={columns}
                  dataSource={dataKci || []}
                  onRow={(record, rowIndex) => {
                    return {
                      onMouseEnter: (event: any) => {
                        setRowIndex(rowIndex);
                      },
                    };
                  }}
                  rowKey="key"
                  rowClassName={(record: any) =>
                    record.key === "section" ? "section-row" : ""
                  }
                  pagination={false}
                />
              ),
            };
          })}
        />
        <br />
      </Contrainer>
      {/* Edit */}
      <Drawer
        width={800}
        closable={false}
        destroyOnClose
        title={
          <Row gutter={8} justify="space-between">
            <Col span={12}>
              <Tag
                color={
                  !oneKci?.kciStatus.includes("DELETE") ? "default" : "#F79626"
                }
              >
                <Badge
                  count={
                    oneKci?.kciStatus.includes("DELETE") ? (
                      <ClockCircleOutlined style={{ color: color.white }} />
                    ) : (
                      <>
                        <ConfigProvider
                          theme={{
                            token: {
                              fontSize: 25,
                            },
                          }}
                        >
                          <Badge
                            color={KCI_STATUS_COLOR_MAPPING[oneKci?.kciStatus]}
                            style={{ paddingBottom: "5px" }}
                          />
                        </ConfigProvider>
                      </>
                    )
                  }
                  color={KCI_STATUS_COLOR_MAPPING[oneKci?.kciStatus]}
                />{" "}
                <Text
                  fontWeight={700}
                  color={
                    !oneKci?.kciStatus.includes("DELETE") ? "black" : "white"
                  }
                >
                  {KCI_STATUS_MAPPING[oneKci?.kciStatus]}
                </Text>
              </Tag>
            </Col>
            <Col>
              <Row gutter={8} justify="space-between">
                <Col style={{ cursor: "pointer" }}>
                  <CopyOutlined onClick={() => setModalCopy(true)} />
                </Col>
                {!oneKci?.kciStatus?.includes("WAIT_APPROVE") && (
                  <Col style={{ cursor: "pointer" }}>
                    <DeleteOutlined onClick={() => setModalDelete(true)} />
                  </Col>
                )}
                {`${profile?.employeeId}` === `${oneKci?.employeeId}` &&
                  oneKci?.kciStatus.includes("DELETE") && (
                    <Col style={{ cursor: "pointer" }}>
                      <Text
                        level={6}
                        fontWeight={700}
                        onClick={() => setModalCancelDelete(!modalCancelDelete)}
                      >
                        ยกเลิกคำขอ
                      </Text>
                    </Col>
                  )}
                <Col style={{ cursor: "pointer" }}>
                  <CloseOutlined
                    onClick={() => {
                      getNoti();
                      setWordingButton(false);
                      setOpenEdit(false);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
        placement="right"
        open={openEdit}
      >
        <Form form={form}>
          {oneKci?.isMyApprove && oneKci?.kciStatus === "WAIT_APPROVE" && (
            <>
              <Row justify={"space-between"} gutter={8}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", backgroundColor: color.success }}
                    onClick={() => kciApprove("APPROVE")}
                  >
                    <CheckOutlined
                      style={{ color: color.white, fontSize: "18px" }}
                    />
                    <Text color="white" fontWeight={700}>
                      อนุมัติ
                    </Text>
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", backgroundColor: color.error }}
                    onClick={() => kciApprove("REJECT")}
                  >
                    <CloseOutlined
                      style={{ color: color.white, fontSize: "18px" }}
                    />
                    <Text color="white" fontWeight={700}>
                      ไม่อนุมัติ
                    </Text>
                  </Button>
                </Col>
              </Row>
              <br />
            </>
          )}
          {oneKci?.isMyApprove && oneKci?.kciStatus.includes("DELETE") && (
            <>
              <Row justify={"space-between"} gutter={8}>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", backgroundColor: color.success }}
                    onClick={() => approveDelete(true)}
                  >
                    <CheckOutlined
                      style={{ color: color.white, fontSize: "18px" }}
                    />
                    <Text color="white" fontWeight={700}>
                      อนุมัติการลบ
                    </Text>
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    style={{ width: "100%", backgroundColor: color.error }}
                    onClick={() => approveDelete(false)}
                  >
                    <CloseOutlined
                      style={{ color: color.white, fontSize: "18px" }}
                    />
                    <Text color="white" fontWeight={700}>
                      ไม่อนุมัติการลบ
                    </Text>
                  </Button>
                </Col>
              </Row>
              <br />
            </>
          )}
          {oneKci?.kciStatus === "WAIT_APPROVE" &&
            `${profile?.employeeId}` === `${oneKci?.employeeId}` && (
              <>
                <Col span={11}>
                  <Alert
                    style={{
                      backgroundColor: "#ffff",
                      borderColor: color.lightgray,
                    }}
                    message={
                      <Text level={6}>
                        ไม่สามารถแก้ไขหรือลบได้ในขณะที่รอการอนุมัติ
                      </Text>
                    }
                    showIcon
                  />
                </Col>
                <br />
              </>
            )}
          <Row>
            {(oneKci?.isMyApprove ||
              `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
            !oneKci?.kciStatus.includes("WAIT") ? (
              <Form.Item
                name="kciDescription"
                noStyle
                rules={[{ validator: validateAtLeastOne }]}
              >
                <Input size="large" autoComplete="off" />
              </Form.Item>
            ) : (
              <Text fontWeight={700} level={4}>
                {oneKci?.kciDescription}
              </Text>
            )}
          </Row>
          <br />
          <Row gutter={8}>
            {oneKci?.employee?.employeeImage ? (
              <Col>
                <Avatar src={oneKci?.employee?.employeeImage} />
              </Col>
            ) : (
              <Col>
                <Avatar>
                  {oneKci?.employee?.firstname.substring(0, 1) || ""}
                  {oneKci?.employee?.lastname.substring(0, 1) || ""}
                </Avatar>
              </Col>
            )}
            <Col>
              <Text fontWeight={600}>
                {oneKci?.employee?.firstname} {oneKci?.employee?.lastname}
              </Text>
              <br />
              <Text color="gray" fontSize={12}>
                {oneKci?.employee?.positionName}
              </Text>
            </Col>
          </Row>
          <br />
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>กลุ่ม</Text>
            </Col>
            <Col span={8}>
              {(oneKci?.isMyApprove ||
                `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
              !oneKci?.kciStatus.includes("WAIT") ? (
                <Form.Item
                  name="target"
                  noStyle
                  rules={[{ validator: validateAtLeastOne }]}
                >
                  <Select
                    options={targetList?.map((c: any) => ({
                      key: c.label,
                      label: c.label,
                      value: c.label,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : (
                <Text fontWeight={400}>
                  {oneKci?.target.includes("Full")
                    ? `${oneKci?.target} ${oneKci?.years}`
                    : oneKci?.target}
                </Text>
              )}
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>หมวดหมู่</Text>
            </Col>
            <Col span={8}>
              {(oneKci?.isMyApprove ||
                `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
              !oneKci?.kciStatus.includes("WAIT") ? (
                <Form.Item
                  name="category"
                  noStyle
                  rules={[{ validator: validateAtLeastOne }]}
                >
                  <Select
                    options={category?.map((c: any) => ({
                      key: c.categoryId,
                      label: c.categoryName,
                      value: c.categoryId,
                    }))}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Tag
                    color={oneKci?.category?.colorCode}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <center>
                      <Text fontWeight={700} color="white">
                        {oneKci?.category.categoryName}
                      </Text>
                    </center>
                  </Tag>
                </>
              )}
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Importance %</Text>
            </Col>
            <Col span={8}>
              <Form.Item
                name="weight"
                noStyle
                rules={[{ validator: validateAtLeastOne }]}
              >
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={
                    (oneKci?.isMyApprove ||
                      `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
                    !oneKci?.kciStatus.includes("WAIT")
                      ? true
                      : false
                  }
                  disabled={
                    (oneKci?.isMyApprove ||
                      `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
                    !oneKci?.kciStatus.includes("WAIT")
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Actual %</Text>
            </Col>
            <Col span={8}>
              <Form.Item name="actual" noStyle>
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={
                    `${profile?.employeeId}` === `${oneKci?.employeeId}` &&
                    oneKci?.kciStatus === "APPROVE"
                      ? true
                      : false
                  }
                  disabled={
                    `${profile?.employeeId}` === `${oneKci?.employeeId}` &&
                    oneKci?.kciStatus === "APPROVE"
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ padding: "3px" }}>
            <Col span={6}>
              <Text fontWeight={600}>Weighted Avg %</Text>
            </Col>
            <Col span={8}>
              <Form.Item name="success" noStyle>
                <Input
                  style={{ color: color.black }}
                  suffix="%"
                  autoComplete="off"
                  bordered={false}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          {(oneKci?.isMyApprove ||
            `${profile?.employeeId}` === `${oneKci?.employeeId}`) &&
            !oneKci?.kciStatus.includes("WAIT") && (
              <Row justify={"end"} gutter={8}>
                {(oneKci?.kciStatus.includes("REJECT") ||
                  oneKci?.kciStatus.includes("DRAFT")) && (
                  <Col span={wordingButton ? 16 : 18}>
                    <Col span={6}>
                      <Button
                        style={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#F4F4F6",
                          borderColor: "#F4F4F6",
                        }}
                        onClick={() => {
                          getNoti();
                          //setOpenEdit(false);
                          editKciByManager("DRAFT");
                        }}
                      >
                        <Text fontWeight={700}>บันทึกแบบร่าง</Text>
                      </Button>
                    </Col>
                  </Col>
                )}
                <Col span={3}>
                  <Button
                    style={{ width: "100%", height: "40px" }}
                    onClick={() => {
                      getNoti();
                      setOpenEdit(false);
                    }}
                  >
                    <Text fontWeight={700}>ยกเลิก</Text>
                  </Button>
                </Col>
                <Col span={wordingButton ? 5 : 3}>
                  <Button
                    style={{
                      width: "100%",
                      backgroundColor: color.navy,
                      height: "40px",
                    }}
                    onClick={() => editKciByManager()}
                  >
                    <Text color="white" fontWeight={700}>
                      {wordingButton ? "ส่งคำขออนุมัติ" : "บันทึก"}
                    </Text>
                  </Button>
                </Col>
              </Row>
            )}
        </Form>
        <Divider />
        <Row>
          <Text level={6} fontWeight={500}>
            Comments ({commentList?.length})
          </Text>
        </Row>
        <br />
        {commentList?.length > 0 ? (
          <div
            style={{
              backgroundColor: "#F3F4FB",
              padding: "15px",
              borderRadius: "6px",
              height:
                commentList?.length < 3
                  ? `${commentList?.length * 120}px`
                  : "300px",
              overflow: "scroll",
            }}
          >
            {commentList?.map((c: any) => (
              <>
                <Row justify={"start"} gutter={8}>
                  <Col>
                    {c?.employee?.employeeImage ? (
                      <Avatar src={c?.employee?.employeeImage} />
                    ) : (
                      <Avatar>
                        {c?.employee?.firstname.substring(0, 1) || ""}
                        {c?.employee?.lastname.substring(0, 1) || ""}
                      </Avatar>
                    )}
                  </Col>
                  <Col>
                    <Text level={6} fontWeight={700}>
                      {c?.employee?.firstname} {c?.employee?.lastname}
                    </Text>
                    <br />
                    <Text color="gray" fontSize={12}>
                      {c?.employee?.positionName}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} gutter={8}>
                  <Col span={20}></Col>
                  <Col span={4}>
                    <Text color="gray" fontSize={12}>
                      {moment(c?.creatDate).format("DD/MM/YYYY, HH:mm")}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"start"} gutter={8}>
                  <Col span={23}>
                    <div
                      style={{
                        width: "104%",
                        //height: "100%",
                        backgroundColor: "#ffff",
                        padding: "15px",
                        borderRadius: "8px",
                        display: "block",
                        wordBreak: "break-word",
                        wordWrap: "break-word",
                        fontFamily: "IBM Plex Sans Thai",
                      }}
                      className="view ql-editor"
                      dangerouslySetInnerHTML={{ __html: c.comment }}
                    ></div>
                  </Col>
                </Row>
                <br />
              </>
            ))}
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#ffff",
              padding: "15px",
              borderRadius: "6px",
              height: "150px",
            }}
          >
            <Empty description={<Text level={6}>ยังไม่มี Comment</Text>} />
          </div>
        )}
        <br />
        <div
          style={{
            backgroundColor: "#F3F4FB",
            padding: "15px",
            borderRadius: "6px",
          }}
        >
          <Row>
            <QuillContainer style={{ width: "100%" }}>
              <ReactQuill
                placeholder="กรอก Comment"
                //ref={quillRef}
                modules={modules}
                onChange={(c) => {
                  setTextEditor(c);
                }}
                value={textEditor}
                theme={"snow"}
              />
            </QuillContainer>
          </Row>
          <br />
          <Row justify={"end"}>
            <Col>
              <Button
                style={{ backgroundColor: color.navy }}
                onClick={() => saveComment(oneKci.kciId)}
              >
                <Text level={6} color="white" fontWeight={700}>
                  บันทึก
                </Text>
              </Button>
            </Col>
          </Row>
        </div>
      </Drawer>
      {/* History */}
      <Drawer
        width={600}
        closable={false}
        destroyOnClose
        title={
          <Row gutter={8} justify="space-between">
            <Col span={12}>
              <img src={icon.kcihistory} />{" "}
              <Text fontWeight={700} level={3}>
                ประวัติการทำรายการ
              </Text>
            </Col>
            <Col>
              <CloseOutlined onClick={() => setOpenHistory(false)} />
            </Col>
          </Row>
        }
        placement="right"
        open={openHistory}
      >
        <>
          {kciHistory?.map((x: any) => (
            <>
              <Text fontSize={16} fontWeight={700}>
                {moment(x[0].createdAt).format("DD/MM/YYYY")}
              </Text>
              <Steps
                direction="vertical"
                items={x.map((y: any) => {
                  return {
                    icon: (
                      <div style={{ paddingTop: "7px", color: color.navy }}>
                        {KCI_HISTORY_STATUS[y.action.replace(" ", "")]}
                      </div>
                    ),
                    title: (
                      <Text color="navy" fontWeight={700}>
                        {moment(y.createdAt).format("HH:mm")}
                      </Text>
                    ),
                    subTitle: (
                      <Text color="navy" fontWeight={700}>
                        {y.action}
                      </Text>
                    ),
                    description: (
                      <>
                        {(y.action === "แก้ไขรายการ" ||
                          y.action === "หัวหน้าได้แก้ไขรายการ") && (
                          <Collapse
                            size="small"
                            items={[
                              {
                                label: (
                                  <Text fontSize={14} fontWeight={400}>
                                    คุณได้แก้ไข KCI
                                  </Text>
                                ),
                                children: (
                                  <>
                                    {y.KCILogDetail.map((z: any) => {
                                      return (
                                        <Row>
                                          <Text fontSize={14}>
                                            <strong>• {z.action}</strong> "
                                            {z.beforeValue}" เป็น "
                                            {z.afterValue}"
                                          </Text>
                                        </Row>
                                      );
                                    })}
                                  </>
                                ),
                              },
                            ]}
                          />
                        )}
                        {y.action === "ย้ายกลุ่ม" && (
                          <Text fontSize={14} fontWeight={400}>
                            คุณได้ทำการย้ายกลุ่มเป้าหมาย{" "}
                            <strong>{y.beforeValue}</strong> เป็น{" "}
                            <strong>{y.afterValue}</strong>
                          </Text>
                        )}
                        {y.action === "คัดลอก" && (
                          <Text fontSize={14} fontWeight={400}>
                            มีการคัดลอก KCI
                          </Text>
                        )}
                        {y.action === "สร้างรายการใหม่" && (
                          <Text fontSize={14} fontWeight={400}>
                            คุณได้สร้างรายการ KCI ใหม่
                          </Text>
                        )}
                        {y.action === "หัวหน้าดำเนินการ" && (
                          <>
                            <Text fontSize={14} fontWeight={400}>
                              หัวหน้า
                              {y.kciStatus === "APPROVE"
                                ? "อนุมัติ"
                                : "ไม่อนุมัติ"}
                            </Text>
                            <br />
                            <Tag color="default">
                              <Badge
                                color={KCI_STATUS_COLOR_MAPPING[y?.kciStatus]}
                              />{" "}
                              <Text fontSize={12} fontWeight={700}>
                                {KCI_STATUS_MAPPING[y?.kciStatus]}
                              </Text>
                            </Tag>
                          </>
                        )}
                        {y.action === "ขออนุมัติลบ KCI" && (
                          <Text fontSize={14} fontWeight={400}>
                            ส่งคำขออนุมัติการลบ KCI แล้ว
                            กรุณารอให้หัวหน้าตอบกลับ
                          </Text>
                        )}
                        {y.action === "ขอยกเลิกลบ KCI" && (
                          <Text fontSize={14} fontWeight={400}>
                            หัวหน้ายกเลิกการลบ KCI
                          </Text>
                        )}
                      </>
                    ),
                    status: "finish",
                  };
                })}
              />
            </>
          ))}
        </>
      </Drawer>
      {modalMove && (
        <Modal
          title={
            <>
              <img src={icon.kcimove} />{" "}
              <Text fontWeight={700} level={2}>
                ย้ายกลุ่ม
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalMove}
          onCancel={() => setModalMove(!modalMove)}
          footer={() => (
            <>
              <Buttons
                text="ยกเลิก"
                bgColor={color.white}
                textColor="black"
                onClick={() => setModalMove(!modalMove)}
              />
              <Buttons text="บันทึก" onClick={() => moveKci()} />
            </>
          )}
        >
          <Row>
            <Text fontWeight={700} level={4}>
              {oneKci?.kciDescription}
            </Text>
          </Row>
          <br />
          <Row justify={"space-between"}>
            <Col span={13}>
              <Text fontWeight={600}>เป้าหมายปัจจุบัน</Text>
            </Col>
            <Col span={11}>
              <Text fontWeight={600}>เป้าหมายใหม่</Text>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <Col span={11}>
              <Input
                value={oneKci?.target}
                disabled
                style={{ color: color.navy }}
              />
            </Col>
            <Col>
              <ArrowRightOutlined />
            </Col>
            <Col span={11}>
              <Select
                style={{ width: "100%" }}
                onChange={(key) => setMoveTarget(key)}
                options={
                  period
                    ?.filter((x) => !x.includes("KCI") && x !== oneKci?.target)
                    .map((y: any) => {
                      return {
                        label: (
                          <Text color="black" fontWeight={700}>
                            {y}
                          </Text>
                        ),
                        value: y,
                        key: y,
                      };
                    }) || []
                }
                placeholder="เลือกเป้าหมายใหม่"
              />
            </Col>
          </Row>
        </Modal>
      )}
      {modalDelete && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                ยืนยันการลบ KCI
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalDelete}
          onCancel={() => setModalDelete(!modalDelete)}
          footer={() => (
            <>
              <Buttons
                text="ยกเลิก"
                bgColor={color.white}
                textColor="black"
                onClick={() => setModalDelete(!modalDelete)}
              />
              <Buttons
                text="ยืนยัน"
                onClick={() => deleteKci(oneKci?.kciId)}
                bgColor={color.error}
              />
            </>
          )}
        >
          <Text>
            โปรดตรวจสอบรายละเอียดก่อนยืนยันการลบ
            เนื่องจากอาจส่งผลต่อการแสดงผลข้อมูลในระบบ หากเลือก KCI ที่มีสถานะ
            ‘อนุมัติ’ ใน Full Year
            คำขอจะถูกส่งให้หัวหน้าเพื่อพิจารณาอนุมัติการลบ
          </Text>
        </Modal>
      )}
      {modalCopy && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                ยืนยันการคัดลอก KCI
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalCopy}
          onCancel={() => setModalCopy(!modalCopy)}
          footer={() => (
            <>
              <Buttons
                text="ยกเลิก"
                bgColor={color.white}
                textColor="black"
                onClick={() => setModalCopy(!modalCopy)}
              />
              <Buttons
                text="ยืนยัน"
                onClick={() => copyKci(oneKci?.kciId)}
                bgColor={color.navy}
              />
            </>
          )}
        >
          <Text>โปรดตรวจสอบรายละเอียดก่อนกดยืนยัน</Text>
        </Modal>
      )}
      {modalShowDataDeleted && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                รายละเอียด KCI ที่ถูกลบ
              </Text>
              <Divider />
            </>
          }
          centered
          open={modalShowDataDeleted}
          onCancel={() => setModalShowDataDeleted(!modalShowDataDeleted)}
          footer={false}
        >
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>คำอธิบาย</Text>
            </Col>
            <Col>
              <Text>{oneKci?.kciDescription}</Text>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>กลุ่ม</Text>
            </Col>
            <Col>
              <Text>
                {oneKci?.target} {oneKci?.years}
              </Text>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>หมวดหมู่</Text>
            </Col>
            <Col>
              <Tag
                color={oneKci?.category?.colorCode}
                style={{ width: "100%", height: "100%" }}
              >
                <Text color="white">{oneKci?.category.categoryName}</Text>
              </Tag>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>Importance</Text>
            </Col>
            <Col>
              <Text>{oneKci?.weight} %</Text>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>Actual</Text>
            </Col>
            <Col>
              <Text>{oneKci?.actual} %</Text>
            </Col>
          </Row>
          <Row justify={"start"}>
            <Col span={8}>
              <Text fontWeight={700}>Weighted Avg</Text>
            </Col>
            <Col>
              <Text>{Number(oneKci?.success).toFixed(1)} %</Text>
            </Col>
          </Row>
        </Modal>
      )}
      {modalCancelDelete && (
        <Modal
          title={
            <>
              <Text fontWeight={700} level={2}>
                ยืนยันการยกเลิกขออนุมัติการลบKCI ที่อนุมัติไปแล้ว
              </Text>
              <Divider />
            </>
          }
          width={600}
          centered
          open={modalCancelDelete}
          onCancel={() => setModalCancelDelete(!modalCancelDelete)}
          footer={() => (
            <>
              <Buttons
                text="ยกเลิก"
                bgColor={color.white}
                textColor="black"
                onClick={() => setModalCancelDelete(!modalCancelDelete)}
              />
              <Buttons
                text="ยืนยัน"
                onClick={() => cancelDeleteKci()}
                bgColor={color.navy}
              />
            </>
          )}
        >
          <Text>
            โปรดตรวจสอบรายละเอียด KCI
            ที่เลือกและปรึกษาหัวหน้าอีกครั้งก่อนยกเลิกคำขออนุมัติการลบ หากยกเลิก
            คำขอนี้จะไม่ถูกส่งต่อเพื่อการลบ
          </Text>
        </Modal>
      )}
    </>
  );
};
export default IndexKci;
